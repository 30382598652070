body {
    font-size: 16px;
    font-family: var(--prezly-font-family-secondary);
    color: var(--prezly-text-color);
    word-break: break-word;
    background: var(--prezly-background-color);
}

h1,
.h1 {
    @include heading-1;

    font-family: var(--prezly-font-family);
}

h2,
.h2 {
    @include heading-2;

    font-family: var(--prezly-font-family);
}

h3,
.h3 {
    @include heading-3;

    font-family: var(--prezly-font-family);
}

p,
.paragraph {
    @include paragraph;
}

a {
    color: var(--prezly-accent-color);
}

.textSmall {
    @include text-small;
}

.textTiny {
    @include text-tiny;
}

label,
.label {
    @include text-label;
}
